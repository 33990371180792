@import url('https://fonts.googleapis.com/css?family=Orbitron');

@font-face {
    font-family: 'Graphik Web';
    src: url('Graphik-Medium-Web.eot');
    src: url('Graphik-Medium-Web.eot?#iefix') format('embedded-opentype'),
        url('Graphik-Medium-Web.woff2') format('woff2'),
        url('Graphik-Medium-Web.woff') format('woff');
    font-weight:  500;
    font-style:   normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Graphik Web';
    src: url('Graphik-Regular-Web.eot');
    src: url('Graphik-Regular-Web.eot?#iefix') format('embedded-opentype'),
        url('Graphik-Regular-Web.woff2') format('woff2'),
        url('Graphik-Regular-Web.woff') format('woff');
    font-weight:  400;
    font-style:   normal;
    font-stretch: normal;
}

a,
a:visited {
    color: #f9f9f9;
}

* {
    box-sizing: border-box;
}

body {
    background-color: #000;
    color: #f9f9f9;
    font-family: 'Graphik Web', sans-serif;
}

h1 {
    display: inline-block;
    font-family: 'Orbitron', sans-serif;
    margin: 0;
    cursor: pointer;
}

header {
    align-items: center;
    display: flex;
    gap: 3em;
    justify-content: center;
    margin: 1em;
    position: relative;
    text-align: center;
    z-index: 1;
}

header input {
    border-radius: 1px;
    border: 1px solid #ccc;
    margin-left: 1em;
    padding: 0.5em;
    font-size: 16px;
}

.remove-link {
    display: block;
    position: absolute;
    right: 10px;
    top: 20px;
}

.flames {
    background-image: url( flamedivider.gif );
    background-position: bottom;
    background-repeat: repeat-x;
    bottom: 0;
    content: " ";
    height: 30px;
    position: fixed;
    width: 100%;
    z-index: 2;
}

.App {
    margin-bottom: 30px;
}

.message-wrapper {
    text-align: center;
}

.devices-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.device-wrapper {
    overflow: hidden;
    padding: 10px;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
    width: 12.5%;
}

.last-seen-wrapper {
    font-size: 12px;
}

.device-wrapper img {
    width: 80%;
}

.offline {
    text-align: center;
}

.coin,
.mushroom,
.marauder {
    display: inline-block;
    height: 36px;
    width: 36px;
    position: relative;
    top: 3px;
}

.marauder {
    filter: invert(1);
}

.coin-clicked,
.mushroom-clicked,
.marauder-clicked {
    top: -4px;
    transition: top 0.2s linear;
}

.coin:hover,
.mushroom:hover {
    cursor: pointer;
}

.rooms {
    margin-left: 60px;
    margin-right: 60px;
    position: relative;
}

.rooms > div:last-child {
    margin-bottom: 80px;
}

.right-now-line {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    position: absolute;
    top: 0;
    bottom: 0;
}

.room-wrapper {
    border: 1px solid #fff;
    position: relative;
    height: 40px;
}

.room-wrapper a {
    color: #000;
    text-decoration: none;
    position: relative;
}

.booking-wrapper {
    background-color: #fff;
    bottom: 0;
    color: #000;
    font-size: 10px;
    position: absolute;
    text-align: center;
    top: 0;
}

.booking-wrapper + .booking-wrapper {
    border-left: 1px solid #000;
}

.room-wrapper a>.tooltiptext {
    visibility: hidden;
    background-color: #fff;
    color: #000;
    text-align: center;
    padding: 8px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    width: auto;
    font-size: 1.3em;
    transform: translateX(-50%);
    top: -40px;
    word-break: keep-all;
}

.room-wrapper a:hover>.tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
}

.room-wrapper a:hover>.tooltiptext {
    visibility: visible;
}

.marauder-map {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.marauder-map .map-wrapper{
    position: relative;
    transform:
        rotateX(51deg)
        rotateZ(43deg);
    transition: transform 0.5s;
    transform-style: preserve-3d;
    /* perspective: 1000px; */
}

/* .map-wrapper.in-view {
    transform: rotateX(0) rotateZ(0);
} */

.marauder-map .device-wrapper {
    font-size: 10px;
    height: 20px;
    left: 0;
    overflow: visible;
    padding: 0;
    position: absolute;
    top: 0;
    transition: all 0.5s;
    width: 20px;
    z-index: 1;
}

.marauder-map .device-wrapper:hover {
    /* transform: rotateX(-5deg) rotateZ(-45deg) rotateY(54deg); */
    transform: rotateZ(-43deg) rotateX(-51deg);
    /* scale: 2; */
}

.marauder-map .person-name {
    font-size: 14px;
    left: -80px;
    opacity: 0;
    position: absolute;
    text-shadow: 0 0 5px #000;
    top: -20px;
    transition: opacity 0.5s;
    width: 200px;
}

.marauder-map .device-wrapper:hover .person-name {
    opacity: 1;
}


.marauder-map .device-wrapper img {
    border-radius: 50%;
}

.marauder-map .last-seen-wrapper,
.marauder-map .device-wrapper img {
    width: 100%;
    height: 100%;
}

.marauder-map .map-wrapper img[role="presentation"] {
    filter: invert(1);
}

@media ( max-width: 940px ) {
    .device-wrapper {
        width: 20%;
    }

    header {
        justify-content: space-between;
        gap: 0;
    }
}

@media ( max-width: 760px ) {
    .device-wrapper {
        width: 25%;
    }

    .remove-link {
        display: none;
    }

    .rooms {
        margin-left: 1em;
        margin-right: 1em;
        position: relative;
    }
}

@media ( max-width: 450px ) {
    h1 {
        font-size: 6vw;
        width: 40%;
    }

    input {
        width: calc( 40% - 2em );
    }

    .device-wrapper {
        width: 33.33333%;
    }
}